import MUIDataTable from 'mui-datatables';
import { React } from 'react';

const AWSSkillBuilderMetricsTable = (props) => {
  const { data } = props;

  const dateFormatter = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${yyyy}-${mm}-${dd}`;
  };

  function getColumn() {
    const columns = [
      {
        name: 'employeeName',
        label: 'Employee Name',
        options: {
          download: true,
          filter: true,
          setCellHeaderProps: () => ({
            style: {
              position: 'sticky',
              background: '#f3f6f9',
              zIndex: 8,
            },
          }),
        },
      }];
    columns.push({
      name: 'courseType',
      label: 'Course Type',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'courseName',
      label: 'Course Name',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'businessUnit',
      label: 'Business Unit',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'progress',
      label: 'Progress',
      options: {
        download: true,
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    columns.push({
      name: 'registrationDate',
      label: 'Registration Date',
      options: {
        download: true,
        filter: true,
        customBodyRender: (value) => dateFormatter(new Date(value)),
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
      },
    });
    return [...columns];
  }

  const options = {
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scroll',
    rowsPerPage: 5,
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'dropdown',
    sortOrder: {
      name: 'UploadedDate',
      sort: true,
      direction: 'desc',
    },
  };

  return (
    <div className="table-responsive">
      <MUIDataTable
        title="User Training Data"
        data={data}
        columns={getColumn()}
        options={options}
      />
    </div>
  );
};

export default AWSSkillBuilderMetricsTable;
