import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from '@reach/router';
import Loader from '../../Loader';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { userAccess, userAccessLoading } = useSelector((state) => state.employeeList);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAccess = () => {
      const route = location.pathname;
      const hasAccessToRoute = userAccess.filter((i) => (i.pageCode.toLowerCase() === route.replace('/', '').toLowerCase() || route === '/') && (i.readAccess || i.writeAccess))?.length > 0;

      if (!hasAccessToRoute) {
        navigate('/accessDenied');
      } else {
        setLoading(false);
      }
    };

    checkAccess();
  }, [location.pathname, navigate, userAccessLoading]);

  if (loading) {
    return <Loader />;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
