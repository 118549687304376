import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { subDays } from 'date-fns';
import axios from 'axios';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Context } from '../Context/MenuContext';
import PageHeader from '../Components/Common/PageHeader';
import Loader from '../Components/Loader';

const ProductRegionReport = () => {
  const [, , , setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  const [hasAccess, setHasAccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const employeeList = useSelector((state) => state.employeeList);
  const {
    userAccess,
  } = employeeList;

  const dateFormatter = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${yyyy}-${mm}-${dd}`;
  };

  function jsonToCsv(jsonData) {
    let csv = '';
    // Get the headers
    const headers = Object.keys(jsonData[0]);
    csv += `${headers.join(',')}\n`;
    // Add the data
    jsonData.forEach((row) => {
      const data = headers.map((header) => JSON.stringify(row[header])).join(','); // Add JSON.stringify statement
      csv += `${data}\n`;
    });
    return csv;
  }

  const getProductRegionData = async () => {
    setLoader(true);
    let url = '/api/Report/GetProductRegionReport';
    if (startDate !== '' && endDate !== '') {
      url = `/api/Report/GetProductRegionReport/${dateFormatter(startDate)}/${dateFormatter(endDate)}`;
    }
    await axios
      .get(url)
      .then((response) => {
        const csvData = jsonToCsv(response.data); // Add .items.data
        // Create a CSV file and allow the user to download it
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ProductRegionReport.csv';
        document.body.appendChild(a);
        a.click();
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    setSelectedMenu('service');
    setSelectedSubMenu('productandRegionReportDownload');
  }, []);

  useEffect(() => {
    if (userAccess?.length > 0
      && userAccess.filter((i) => i.pageCode === 'productRegionReport' && i.writeAccess)?.length > 0) {
      setHasAccess(true);
    }
  }, [userAccess]);

  const handleDateChange = (data) => {
    setStartDate(data[0]);
    setEndDate(data[1]);
  };
  return (
    <>
      {loader ? <Loader /> : ''}
      <div className="wrapper">
        <div className="main">
          <div className="row">
            <div className="col-lg-12">
              <PageHeader pageTitle="Product and Region Report Download" />
              <div className="page-content">
                <div className="card">
                  <div className="card-body">
                    <div className="row justify-content-center text-center">
                      <div className="col-md-6 border rounded p-4 shadow-sm">
                        <p className="lead">
                          Product and Region Report
                        </p>
                        <div className="d-flex justify-content-center">
                          <ReactDatePicker
                            wrapperClassName="date-picker prod-reg-datepicker"
                            selectsRange
                            placeholderText="Select a date range"
                            startDate={startDate}
                            endDate={endDate}
                            minDate={subDays(new Date(), (365 * 3))}
                            maxDate={new Date()}
                            onChange={handleDateChange}
                            dateFormat="MM/dd/yyyy"
                          />
                          {hasAccess
                            ? (
                              <button type="button" className="btn btn-primary ms-3" onClick={getProductRegionData}>
                                Download Report (csv)
                              </button>
                            ) : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRegionReport;
