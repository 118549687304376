import {
  React, useContext, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { subMonths } from 'date-fns';
import { Context as MenuContext } from '../Context/MenuContext';
import PageHeader from '../Components/Common/PageHeader';
import ScoreCard from '../Components/Common/ScoreCard';
import AWSSkillBuilderMetricsTable from '../Components/AWSSkillBuilderMetrics/AWSSkillBuilderMetricsTable';
import AWSSkillBuilderMetricsFilter from '../Components/AWSSkillBuilderMetrics/AWSSkillBuilderMetricsFilter';
import Loader from '../Components/Loader';
import Alert from '../Components/Common/Alert';

import { getSkillBuilderMetricsData } from '../Store/actions/skillBuilderAction';
import LineChart from '../Components/Common/Charts/LineChart';

const AWSSkillBuilderMetrics = () => {
  const filterObject = {
    courseType: 'all',
    businessUnit: 'all',
    startDate: subMonths(new Date(), 6),
    endDate: new Date(),
  };
  const [trainingData, setTrainingData] = useState([]);
  const [trainingTypes, setTrainingTypes] = useState(null);
  const [businessUnits, setBusinessUnits] = useState(null);
  const [businessUnitsCount, setBusinessUnitsCount] = useState(0);
  const [ShowErrMsg, setShowErrMsg] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [filter, setFilter] = useState(filterObject);

  const [series, setSeries] = useState([]);
  const [category, setCategory] = useState([]);

  const dispatch = useDispatch();
  const skillbuilder = useSelector((state) => state.skillBuilderMetrics);
  const {
    skillBuilderMatricsData,
    skillBuilderMatricsLoading, errorSkillBuilderMatrics,
  } = skillbuilder;

  const [, , , setSelectedMenu] = useContext(MenuContext);

  const chartOptions = {
    series,
    chart: {
      height: 400,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    fill: {
      type: 'gradient',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: category,
      labels: {
        text: 'Training Type',
      },
    },
    yaxis: {
      labels: {
        formatter(value) {
          return value;
        },
      },
    },
    legend: {
      position: 'right',
      fontSize: '10px',
      width: 200,
      labels: {
        text: 'Top Services',
      },
    },
  };

  // Function to filter data by date range
  function filterDataByDateRange(data, filter) {
    const start = new Date(filter.startDate);
    const end = new Date(filter.endDate);
    return data.filter((item) => {
      const registrationDate = new Date(item.registrationDate);
      return registrationDate >= start && registrationDate <= end;
    });
  }

  function filterData(data, filter) {
    return data.filter((item) => {
      const matchesCourseType = filter.courseType === 'all' || item.courseType === filter.courseType;
      const matchesBusinessUnit = filter.businessUnit === 'all' || item.businessUnit === filter.businessUnit;

      const registrationDate = new Date(item.registrationDate);
      const matchesDateRange = (!filter.startDate || registrationDate >= new Date(filter.startDate))
        && (!filter.endDate || registrationDate <= new Date(filter.endDate));

      return matchesCourseType && matchesBusinessUnit && matchesDateRange;
    });
  }

  function dataFormatForLineChart(data) {
    const startDate = new Date(filter.startDate);
    const endDate = new Date(filter.endDate);
    const timeDiff = endDate - startDate;
    const oneMonthDuration = 30 * 24 * 60 * 60 * 1000;
    const granularity = timeDiff <= oneMonthDuration ? 'daily' : 'monthly';

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return granularity === 'daily' ? `${year}-${month}-${day}` : `${year}-${month}`;
    };

    const filteredData = filterDataByDateRange(data, filter);

    const uniqueCategories = Array.from(
      new Set(filteredData.map(({ registrationDate }) => formatDate(registrationDate))),
    ).sort();

    const countByCourseAndCategory = filteredData.reduce((acc, { courseType, registrationDate }) => {
      const category = formatDate(registrationDate);
      if (!acc[courseType]) acc[courseType] = {};
      if (!acc[courseType][category]) acc[courseType][category] = 0;
      acc[courseType][category]++;
      return acc;
    }, {});

    const result = Object.keys(countByCourseAndCategory).map((courseType) => {
      const data = uniqueCategories.map(
        (category) => countByCourseAndCategory[courseType][category] || 0,
      );
      return { name: courseType, data };
    });

    setSeries(result);
    setCategory(uniqueCategories);
  }

  const apiCall = () => {
    const apiUrl = '/api/SkillBuilder/GetTrainingData';
    dispatch(getSkillBuilderMetricsData(apiUrl));
  };

  useEffect(() => {
    setSelectedMenu('awsskillbuildermetrics');
    apiCall();
  }, []);

  useEffect(() => {
    if (errorSkillBuilderMatrics !== '') {
      setErrorMessage(errorSkillBuilderMatrics);
      setShowErrMsg(true);
    }
  }, [errorSkillBuilderMatrics]);

  function findUniquevalues(data, col) {
    const uniqueValue = data.reduce((acc, obj) => {
      if (!acc.includes(obj[col])) {
        acc.push(obj[col]);
      }
      return acc;
    }, []);
    return uniqueValue;
  }

  const handleChange = (e, name) => {
    const { value } = e;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  useEffect(() => {
    const resultData = filterData(skillBuilderMatricsData, filter);
    setTrainingData(resultData);
    const businessUnit = findUniquevalues(resultData, 'businessUnit');
    setBusinessUnitsCount(businessUnit.length);
    dataFormatForLineChart(resultData);
  }, [filter]);

  useEffect(() => {
    setTrainingData(skillBuilderMatricsData);
    const courseType = findUniquevalues(skillBuilderMatricsData, 'courseType');
    const businessUnit = findUniquevalues(skillBuilderMatricsData, 'businessUnit');
    setTrainingTypes(courseType);
    setBusinessUnits(businessUnit);
    setBusinessUnitsCount(businessUnit?.length);
    dataFormatForLineChart(skillBuilderMatricsData);
  }, [skillBuilderMatricsData]);

  return (
    <>
      <div className="wrapper">
        <div className="main">
          {skillBuilderMatricsLoading
            ? (<Loader />)
            : (ShowErrMsg
              ? (
                <div className="col-12">
                  <Alert
                    message={errorMessage}
                    alertClass="light-danger"
                    onClose={() => setShowErrMsg(false)}
                  />
                </div>
              ) : (
                <>
                  <div className="page-content">
                    <PageHeader pageTitle="AWS Skill Builder" />
                    <AWSSkillBuilderMetricsFilter
                      trainingTypes={trainingTypes}
                      businessUnits={businessUnits}
                      handleChange={handleChange}
                      filter={filter}
                      setFilter={setFilter}
                    />
                    <div className="d-flex">
                      <ScoreCard icon={<FontAwesomeIcon icon={faUserFriends} />} title="Total Users" count={trainingData?.length} infoText="Total number of users getting training" />
                      <ScoreCard icon={<FontAwesomeIcon icon={faBriefcase} />} title="Business Units" count={businessUnitsCount} infoText="Total number of business units belong to users" />
                    </div>
                    <div className="card mb-3">
                      <div className="card-body">
                        <h5>Training Request over Time</h5>
                        <LineChart series={chartOptions.series} options={chartOptions} />
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <AWSSkillBuilderMetricsTable data={trainingData} />
                      </div>
                    </div>
                  </div>

                </>
              ))}
        </div>
      </div>
    </>
  );
};

export default AWSSkillBuilderMetrics;
