import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import SideNavigator from './Components/SideNavigator';
import Header from './Components/Header';
import Home from './Screens/Home';
import SignIn from './Components/SignIn';
import Loader from './Components/Loader';

import CostReportingUpload from './Screens/CostReportingUpload';
import AWSAzureImageUpload from './Screens/AWSAzureImageUpload';
import ProductRegionReport from './Screens/ProductRegionReport';
import Feedback from './Screens/Feedback';
import UserAccess from './Screens/UserAccess';
import Modules from './Screens/Modules';
import EmployeeTraining from './Screens/EmployeeTraining';
import NewAnnouncement from './Screens/NewAnnouncement';
import AccessDenied from './Components/Common/AccessDenied';
import { FeedbackContext } from './Context/FeedbackContext';
import { MenuContext } from './Context/MenuContext';
import { FileUploadContext } from './Context/FileUploadContext';
import ArcusVisitorPage from './Screens/ArcusVisitorPage';
import ModuleAccess from './Screens/ModuleAccess';
import ServiceTrendingTool from './Screens/ServiceTrendingTool';
import AWSSkillBuilderMetrics from './Screens/AWSSkilBuilderMetrics';
import { getUserAccess } from './Store/actions/userAccessAction';

import ProtectedRoute from './Components/Common/ProtectedRoute';

import { UserAccessContext } from './Context/UserAccessContext';

const App = (props) => {
  const { location, setLocation } = props;
  const { pathname } = location;

  useEffect(() => {
    setLocation(pathname);
  }, [pathname]);

  return (
    <>
      {props.children}
    </>
  );
};

export default () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [location, setLocation] = useState('');

  const employeeList = useSelector((state) => state.employeeList);
  const {
    userAccess, userAccessLoading,
  } = employeeList;

  useEffect(() => {
    if (userAccess?.length > 0
      && userAccess.filter((i) => (i.pageCode === location.replace('/', '') || location === '/') && (i.readAccess || i.writeAccess))?.length > 0) {
      setHasAccess(true);
    }
  }, [userAccess, location]);

  useEffect(() => {
    const apiUrl = '/api/module/GetUserAccessModule';
    dispatch(getUserAccess(apiUrl));
  }, [user]);

  function whoAmI() {
    axios
      .get('/api/auth/whoami')
      .then((response) => {
        const user = response.data;
        if (user.isAuthenticated === true) {
          setUser(response.data);
        } else {
          setUser(null);
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          console.log(e.message);
        }
      })
      .finally(() => {
        setLoaded(true);
      });
  }
  useEffect(() => {
    whoAmI();
  }, []);

  return (
    <>
      {' '}
      {loaded && !userAccessLoading
        ? (
          <>
            <div className="main-container">
              <UserAccessContext>
                <MenuContext>
                  <Header user={user} />
                  <div className="wrapper">
                    {user && (<SideNavigator user={user} />)}
                    <FeedbackContext>
                      <FileUploadContext>
                        <Router>
                          <App path="/" setLocation={(path) => setLocation(path)}>
                            {user && (<Home path="/" user={user} />)}
                            {user && (<ProtectedRoute path="/costReportingUpload" component={CostReportingUpload} hasAccess={hasAccess} />)}
                            {user && (<ProtectedRoute path="/AWSAzureImageUpload" component={AWSAzureImageUpload} hasAccess={hasAccess} />)}
                            {user && (<ProtectedRoute path="/ProductRegionReport" component={ProductRegionReport} hasAccess={hasAccess} />)}
                            {user && (<ProtectedRoute path="/userAccess" component={UserAccess} hasAccess={hasAccess} user={user} />)}
                            {user && (<ProtectedRoute path="/moduleAccess" component={ModuleAccess} hasAccess={hasAccess} />)}
                            {user && (<ProtectedRoute path="/modules" component={Modules} hasAccess={hasAccess} user={user} />)}
                            {user && (<ProtectedRoute path="/newAnnouncement" component={NewAnnouncement} hasAccess={hasAccess} user={user} />)}
                            {user && (<ProtectedRoute path="/feedback" component={Feedback} hasAccess={hasAccess} />)}
                            {user && (<ProtectedRoute path="/employeeTraining" component={EmployeeTraining} hasAccess={hasAccess} />)}
                            {user && (<ProtectedRoute path="/awsskillbuildermetrics" component={AWSSkillBuilderMetrics} hasAccess={hasAccess} />)}
                            {user && (<ProtectedRoute path="/arcusVisitor" component={ArcusVisitorPage} hasAccess={hasAccess} />)}
                            {user && (<ProtectedRoute path="/servicetrendingtool" component={ServiceTrendingTool} hasAccess={hasAccess} />)}
                            <AccessDenied path="/accessDenied" />
                            <SignIn default />
                          </App>
                        </Router>
                      </FileUploadContext>
                    </FeedbackContext>
                  </div>
                </MenuContext>
              </UserAccessContext>
            </div>
          </>
        )
        : <Loader />}
      <Toaster
        position="bottom-right"
        toastOptions={{
          style: {
            padding: '1rem',
            fontSize: '1rem',
          },
          success: {
            style: {
              background: '#d1e7dd',
              color: '#212529',
            },
          },
          error: {
            style: {
              background: '#f8d7da',
              color: '#212529',
            },
          },
        }}
      />
    </>
  );
};
