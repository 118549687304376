import { React, useEffect, useState } from 'react';
import { subDays } from 'date-fns';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';

const AWSSkillBuilderMetricsFilter = (props) => {
  const {
    trainingTypes, businessUnits, handleChange, filter, setFilter,
  } = props;
  const [trainingType, setTrainingType] = useState(trainingTypes);
  const [businessUnit, setBusinessUnit] = useState(businessUnits);
  const onChange = (dates) => {
    const [start, end] = dates;
    setFilter({
      ...filter,
      startDate: start,
      endDate: end,
    });
  };

  useEffect(() => {
    const trainingList = [{ value: 'all', label: 'All' }];
    for (let index = 0; index < trainingTypes?.length; index++) {
      trainingList.push({ value: trainingTypes[index], label: trainingTypes[index] });
    }
    const businessUnitList = [{ value: 'all', label: 'All' }];
    for (let index = 0; index < businessUnits?.length; index++) {
      businessUnitList.push({ value: businessUnits[index], label: businessUnits[index] });
    }
    setTrainingType(trainingList);
    setBusinessUnit(businessUnitList);
  }, [trainingTypes]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#6c757d' : '#6c757d',
      fontSize: '0.8rem',
      fontWeight: '400',
      lineHeight: '1.5',
      backgroundColor: '#ecf0f1',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      appearance: 'none',
      borderRadius: '0',
    }),
  };
  return (
    <div className="card h-100 mb-3">
      <div className="card-body">
        <h5>AWS Skill Builder Filters</h5>
        <div className="row align-items-end">
          <div className="col-md-3 mb-3">
            <label>Training Type</label>
            <Select
              name="courseType"
              className="react-select"
              styles={customStyles}
              placeholder="Training Type"
              options={trainingType}
              onChange={(v) => handleChange(v, 'courseType')}
            />
          </div>
          <div className="col-md-3 mb-3">
            <label>Business Unit</label>
            <Select
              name="businessUnit"
              className="react-select"
              styles={customStyles}
              placeholder="Business Unit"
              options={businessUnit}
              onChange={(v) => handleChange(v, 'businessUnit')}
            />
          </div>
          <div className="col-md-3 mb-3">
            <label>Timespan</label>
            <ReactDatePicker
              wrapperClassName="date-picker"
              style={{ display: 'block' }}
              selectsRange
              placeholderText="Select a date range"
              startDate={filter.startDate}
              endDate={filter.endDate}
              minDate={subDays(new Date(), (365 * 3))}
              maxDate={new Date()}
              onChange={onChange}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AWSSkillBuilderMetricsFilter;
